const $ = require('jquery');
global.$ = global.jQuery = $;

require('slick-carousel');
require('slick-carousel/slick/slick.css');
require('slick-carousel/slick/slick-theme.css');

require('bootstrap');

require('jquery-ui/ui/widgets/slider');
require('jquery-ui/themes/base/slider.css');
